import "./account.scss";
import "../../variable.scss";
import { FaAngleRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userAxios } from "../../utils/config";
import axios from "axios";
import partnerLogo from "../../assets/img/partner.svg";
import { auth } from "../../services/auth0.service";
import { handleLogout } from "../../utils/common";

const Account = ({}) => {
  const [data, setData] = useState();
  const [address, setAddress] = useState();
  const navigate = useNavigate();
  const email = sessionStorage.getItem("email");
  const userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");

  function openWidgetArticle(articleId) {
    window.FreshworksWidget("open", "article", { id: articleId });
  }

  const accountDetails = async () => {
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      // console.log("response from account ==> ", response);
      setAddress(JSON.parse(response?.data?.userInfo?.user_metadata?.address));
      setData(response?.data?.userInfo);
    } catch (error) {
      // console.log("error", error);
    }
  };
  let phoneNumber = data?.user_metadata?.phone_number;

// if (phoneNumber && !phoneNumber.startsWith("0")) {
// phoneNumber = "0" + phoneNumber;
// }

const handleLogoutButton = () => {
  handleLogout(email, false);
};
  useEffect(() => {
    if (userId) {
      accountDetails();
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className="commonWrapper">
      <h2>Your account</h2>
      <div className="text-start account-mb">
        <p>
          <strong>
            {data?.given_name} {data?.family_name}
          </strong>
        </p>
        <p className="m-0">
          {data?.user_metadata?.phone_number}
          <br />
          {data?.email}
          <br />
          {address?.street_address} {address?.locality} {address?.region}{" "}
          {address?.country}
        </p>
      </div>
      <ul className="accountSub">
        <li>
          <Link to="/account/personal-detail">
            Personal Details <FaAngleRight />
          </Link>
        </li>
        {/* <li>
          <Link to="/account/documents">
            Membership Documents <FaAngleRight />
          </Link>
        </li> */}
        <li>
          <Link to="/account/security">
            Security
            <FaAngleRight />
          </Link>
        </li>
        <li>
          <Link to="/account/about">
            About
            <FaAngleRight />
          </Link>
        </li>
        <li>
          {/* <Link to="/terms-conditions">
            Terms & Conditions
            <FaAngleRight />
          </Link> */}
          <a to="#" className="custom-link" onClick={() => openWidgetArticle(101000370094)}>Terms & Conditions<FaAngleRight /></a>
        </li>
        <li>
          <a to="#" className="custom-link" onClick={() => handleLogoutButton()}>
            <div >Log out</div>
            <FaAngleRight />
          </a>
        </li>
      </ul>
      <div className="text-center partnerWith">
        <p>In partnership with</p>
        <img className="img-fluid" src={partnerLogo} />
      </div>
    </div>
  );
};

export default Account;

import "./security.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import { FaAngleRight } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import partnerLogo from "../../../assets/img/partner.svg";
import { useLocation } from "react-router-dom";

const Security = ({}) => {

  return (
    <div className="userWrapper">
      <Form className="commonWrapper">
        <Link to="/account">
          <h3>
            <img className="img-fluid me-2" src={FaAngleLeft} />
            Security
          </h3>
        </Link>
        <hr />
        <ul className="accountSub">
          <li>
            <Link to="new-password">
              Change password <FaAngleRight />
            </Link>
          </li>
        </ul>
      </Form>
      <div className="partnerShip">
        <p>In partnership with</p>
        <img className="img-fluid" src={partnerLogo} />
      </div>
    </div>
  );
};

export default Security;

import "./HealthAssesment.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "../../../component/Button/Button";
import partnerLogo from "../../../assets/img/partner.svg";
import health from "../../../assets/img/health.svg";
import timer from "../../../assets/img/timer.svg";

const HealthAssesment = ({ }) => {


	return (
		<div className="userWrapper">
			<h2>Welcome to your health assessment</h2>
			<Container>
				<div className="assesment-container">
					<div className="main-info">
						<p>
							Our health assessments focus on preventative health, informing you of potential issues and reinforcing good behaviors to support your health.
						</p>
						<img src={health} />
					</div>
					<div className="time-info">
					  <img src={timer} />
					  <span>Will take around 15 minutes</span>
					</div>
					<Link to="/health-assesment-steps">
					  <Button name="Start assessment" />
					</Link>
				</div>
			</Container>
			<div className="partnerShip">
				<p>In partnership with</p>
				<img src={partnerLogo} />
			</div>
		</div>
	);
};

export default HealthAssesment;

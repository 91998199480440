import "./HelpCrisis.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import newWindow from "../../../assets/img/open-tab.svg";
import partnerLogo from "../../../assets/img/partner.svg";
const HelpCrisis = ({}) => {
  return (
    <>
    <div className="commonWrapper helpCrisis">
      <div className="d-flex justify-content-between">
        <Link to="/mood-journal">
          <h3>
            <img className="img-fluid me-2" src={FaAngleLeft} alt="" />
            Help in a crisis
          </h3>
        </Link>
      </div>
      <hr />

      <p>
        If you want to talk to someone about how you feel, what you’re
        experiencing or how to deal with somone else’s behaviour, you can
        contact:
      </p>

      <ul className="accountSub mt-48">
        <li>
          <div className="d-flex justify-content-between w-100 align-items-center me-3">
            <div className="left-info">
              <p>The Samaritans</p>
              <small>24 hours</small>
            </div>
            <a href="tel:116-123" className="tel">
              116-123
            </a>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between w-100 align-items-center me-3">
            <div className="left-info">
              <p>SANEline</p>
              <small>4pm - 10pm</small>
            </div>
            <a href="tel:0300-304-7000" className="tel">
              0300-304-7000
            </a>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between w-100 align-items-center me-3">
            <div className="left-info">
              <p>Mind Helpline</p>
              <small>Mon-Fri: 9am - 6pm</small>
            </div>
            <a href="tel:0300-123-3393" className="tel">
              0300-123-3393
            </a>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between w-100 align-items-center me-3">
            <div className="left-info">
              <p>Mind: What can I do to help myself cope? </p>
            </div>
            <a
              className="p-0"
              href="https://www.mind.org.uk/need-urgent-help/"
              target={"_blank"}
            >
              <img src={newWindow} alt="" />
            </a>
          </div>
        </li>
      </ul>

      
    </div>
    <div className="commonWrapper  text-center partnerWith">
        <p>In partnership with</p>
        <img className="img-fluid" src={partnerLogo} />
      </div>
    </>
  );
};

export default HelpCrisis;

import React from "react";
import FreshdeskWidget from "@personare/react-freshdesk-widget";

const Cookies = () => {
  return (
    <div>
      <FreshdeskWidget url="https://medipartner.freshdesk.com/support/solutions/articles/101000447402-medipartner-privacy-policy-cover-today" />
    </div>
  );
};

export default Cookies;

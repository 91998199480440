import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  journalEntries: [],
  journalID: "",
  accessToken: "", // New accessToken state
};

const moodJournalSlice = createSlice({
  name: "Mood Journal",
  initialState,
  reducers: {
    setEntries: (state, action) => {
      state.journalEntries = [...action.payload];
    },
    setID: (state, action) => {
      state.journalID = action.payload;
    },
    prevData: (state, action) => {
      state.journalID = action.payload;
    },
    nextData: (state, action) => {
      state.journalID = action.payload;
    },
    setAccessToken: (state, action) => { // New reducer for setting accessToken
      state.accessToken = action.payload;
    },
  },
});

export const { setEntries, setID, prevData, nextData, setAccessToken } =
  moodJournalSlice.actions;
export default moodJournalSlice.reducer;

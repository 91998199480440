import "./AssesmentStepThree.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from '../../../../assets/img/angle-left.svg';
import Button from "../../../../component/Button/Button";
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import beer from '../../../../assets/img/beer.svg';
import wine from '../../../../assets/img/wine.svg';
import shot from '../../../../assets/img/shot.svg';


const AssesmentStepThree = ({ handleOptionSelect}) => {

	return (
		<div className="assesment-steps_marking">
			<div className="assesment-steps_marking_inner">
				<h2>Healthy habits</h2>
				<p>Do you use any tobacco products? (cigaretts, cigars, pipe, vaping, e-cigaretts, Juuling,  & chewing)</p>
				{/* <p>Do you routinely eat fatty fish such as salmon or tuna or take a vitamin D supplement containing at least 800 IUs of vitamin D per day?</p> */}
				{/* <div className="assesment-steps_marking_inner_options">
				<Link className="option-row">
					<span>Yes</span>
					<img src={FaAngleLeft} />
				</Link>
				<Link className="option-row">
					<span>No</span>
					<img src={FaAngleLeft} />
				</Link>
			</div> */}

				{/* When Checkbox options will be shown */}
				{/* <div className="assesment-steps_marking_inner_options">
					<div className="checkbox-group">
						<div className="check-row">
							<div className="custom-checkbox">
								<input type="checkbox" checked id="Cigarettes" />
								<label for="Cigarettes"></label>
							</div>
							<div className="check-label">
								Cigarettes
							</div>
						</div>
						<div className="checked-more-info">
							<Form className="formContainer shift-top">
								<div className="row">
									<div className="col-md-4 custom-gutter">
										<Form.Group controlId="formBasicEmail">
											<Form.Label>
												<span>Quantity</span>
											</Form.Label>
											<Form.Control type="text" placeholder="Quantity" />
										</Form.Group>
									</div>
									<div className="col-md-8 custom-gutter">
										<Form.Group controlId="formBasicEmail">
											<Form.Label>
												<span>Frequency</span>
											</Form.Label>
											<Form.Select aria-label="Default select example" className="form-control">
												<option value="1">Daily</option>
												<option value="2">Weekly</option>
												<option value="3">Monthly</option>
											</Form.Select>
										</Form.Group>
									</div>
								</div>
							</Form>
						</div>
						<div className="check-row">
							<div className="custom-checkbox">
								<input type="checkbox" id="Pipe" />
								<label for="Pipe"></label>
							</div>
							<div className="check-label">
								Pipe
							</div>
						</div>
						<div className="check-row">
							<div className="custom-checkbox">
								<input type="checkbox" id="Vaping" />
								<label for="Vaping"></label>
							</div>
							<div className="check-label">
								Vaping
							</div>
						</div>
						<div className="check-row">
							<div className="custom-checkbox">
								<input type="checkbox" id="Ecigarettes" />
								<label for="Ecigarettes"></label>
							</div>
							<div className="check-label">
								E-cigarettes
							</div>
						</div>
						<div className="check-row">
							<div className="custom-checkbox">
								<input type="checkbox" id="Juuling" />
								<label for="Juuling"></label>
							</div>
							<div className="check-label">
								Juuling
							</div>
						</div>
						<div className="check-row">
							<div className="custom-checkbox">
								<input type="checkbox" id="Chewing" />
								<label for="Chewing"></label>
							</div>
							<div className="check-label">
								Chewing
							</div>
						</div>

					</div>
					<Button name="Continue" />
				</div> */}


				{/* When Options related to the Alcohol */}

				<div className="drinks-options">
					<p>One drink equals:</p>
					<ListGroup>
						<ListGroup.Item>
							<div className="list-inner">
								<img src={beer} />
								<span>568ml beer</span>
							</div>
						</ListGroup.Item>
						<ListGroup.Item>
							<div className="list-inner">
								<img src={wine} />
								<span>175ml wine</span>
							</div>
						</ListGroup.Item>
						<ListGroup.Item>
							<div className="list-inner">
								<img src={shot} />
								<span>35ml spirits (one shot)</span>
							</div>
						</ListGroup.Item>
					</ListGroup>
				</div>
				<div className="assesment-steps_marking_inner_options">
					<Link className="option-row"onClick={handleOptionSelect}>
						<span>1 or 2</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row" onClick={handleOptionSelect}>
						<span>3 or 4</span>
						<img src={FaAngleLeft} />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AssesmentStepThree;

import "./SymptomChecker.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";

const SymptomChecker = ({}) => {
  return (
    <div className="symptom-checker">
      {/* <div className="symptom-checker_blueBar">
        <Container>
          <span>111 Online</span>
        </Container>
      </div>
      <div className="symptom-checker_section">
        <Container>
          <div className="symptom-checker_section_inner">
            <h2>Get medical help</h2>
            <p>111 online can tell you</p>
            <ul>
              <li>
                where to get help for your symptoms, if you're not sure what to
                do
              </li>
              <li>how to find general health information and advice</li>
              <li>
                where to get an emergency supply of your prescribed medicine
              </li>
              <li>how to get a repeat prescription</li>
            </ul>
            <button className="btn start-btn">Start</button>
            <hr />
            <div className="bottom-info">
              <h2>111 callback times</h2>
              <p>
                The 111 callback service might be busy.{" "}
                <Link>Get information about 111 callbacks.</Link>
              </p>
            </div>
          </div>
        </Container>
        <div className="symptom-checker_bottom-info">
          <Container>
            <p>
              Always follow any medical advice you've already been given by your
              doctor. See our <Link>terms</Link>
            </p>
            <Link>
              Other ways to contact 111 if you have a hearing problem or need
              help in other.
            </Link>
          </Container>
        </div>
      </div> */}
      <Container>
        <iframe
          src="https://111.nhs.uk/"
          width={"100%"}
          style={{ overflow: "scroll", minHeight: "160vh" }}
          //   scrolling="no"
        ></iframe>
      </Container>
    </div>
  );
};

export default SymptomChecker;

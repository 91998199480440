import React from "react";
import FreshdeskWidget from "@personare/react-freshdesk-widget";
import { Helmet } from "react-helmet";

const Terms = () => {
  function openWidgetArticle() {
    window?.FreshworksWidget("open", "article", { id: 101000370094 });
  }
  //   window.fwSettings = { widget_id: 47000000016 };
  //   !(function () {
  //     if ("function" != typeof window.FreshworksWidget) {
  //       var n = function () {
  //         n.q.push(arguments);
  //       };
  //       (n.q = []), (window.FreshworksWidget = n);
  //     }
  //   })();

  //   const insertScriptFreshWidget = (
  //     <Helmet>
  //       <script>
  //         {`function openWidgetArticle() {FreshworksWidget('open', 'article', { id: 101000447402 });}window.fwSettings = { 'widget_id':47000000016};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`}
  //       </script>
  //     </Helmet>
  //   );
  return (
    <div>
      {/* <>{insertScriptFreshWidget}</> */}
      <FreshdeskWidget
        // width={"100%"}
        url="https://support.smartinsurance.medipartneronline.com/en/support/solutions/articles/101000491567-medipartner-limited-terms-conditions-smart-insurance"
        style={{ overflow: "scroll", minHeight: "160vh" }}
      />
    </div>
  );
};

export default Terms;

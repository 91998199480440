import * as Yup from "yup";

export const SignUpSchema = Yup.object().shape({
  insuranceNumber: Yup.string()
    .required("Insurance policy is required"),
    // .typeError("Insurance must be a number"),
  day: Yup.number()
    .max(31)
    .required("Day is required")
    .typeError("Day must be a number"),
  month: Yup.number()
    .max(12)
    .required("Month Of Birth is required")
    .typeError("Month must be a number"),
  year: Yup.number()
    .max(new Date().getFullYear())
    .required("Year is required")
    .typeError("Year must be a number"),
  // email: Yup.string()
  //   .email("Invalid email address")
  //   .required("Email is required"),
   email: Yup.string()
    .test('valid-email', 'Invalid email address', function (value) {
      if (value) {
        // Convert the email to lowercase for validation
        const lowercaseEmail = value.toLowerCase();
        return Yup.string().email().isValidSync(lowercaseEmail);
      }
      return true; // Allow empty email field
    })
    .required('Email is required'),
  terms: Yup.boolean().oneOf([true], "Please agree Terms & Conditions"),
  termsTwo: Yup.boolean().oneOf([true], "Please agree Privacy Policy."),
});
export const SignInSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export const ContactNumberSchema = Yup.object().shape({
  contactNumber: Yup.string()
    .matches(/^(0\d{9}|(\+44)?\d{10})$/, 'Invalid contact number')
    .required("Contact number is required"),
});

export const PersonalDetailSchema = Yup.object().shape({
  given_name: Yup.string()
    .required("First name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  family_name: Yup.string()
    .required("Last name is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  phone_number: Yup.number()
    .required("Phone number  is required")
    .typeError("Please enter valid phone number"),
  // .min(10, "Phone number must be equal to 12")
  // .max(10, "Phone number must be equal to 12"),
  street_address: Yup.string(),
  region: Yup.string(),
  country: Yup.string()
    // .required("Country is required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  postal_code: Yup.string(),
});

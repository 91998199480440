import "./Disclaimer.scss";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "../../../component/Button/Button";
import partnerLogo from "../../../assets/img/partner.svg";
import healthIcons from "../../../assets/img/health-icons.svg";
import timer from "../../../assets/img/timer.svg";
import { useSelector } from "react-redux";
import alert from "../../../assets/img/alert.svg";
import { useEffect, useState } from "react";
import axios from "axios";
import { axiosInstance } from "../../../utils/config/axiosInstance";

const Disclaimer = ({ }) => {
    const message = useSelector((store) => store?.nurseChampion);
    var date = new Date(message?.date);
    const newDate = date.toLocaleDateString("en-US", { dateStyle: "medium" });
    const [data, setData] = useState([]);
    let userId = sessionStorage.getItem("authUserId");
    let newUserId;
    if (userId.startsWith("auth0|")) {
        newUserId = userId.slice(6);
    }
    let dates = new Date().toLocaleDateString();
    // console.log("dates", dates);
    const fetchFilterRequests = async () => {
        // console.log(
        //   "Api fetcher==>",
        //   `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/get=${newUserId}`
        // );
        // setLoading(true);
        try {
            const response = await axiosInstance.get(
                `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/getRemoteGP?id=${newUserId}`
            );
            // console.log("Mood journal list response", response);
            // response.forEach((element) => {
            //   const date = new Date(element.createdAt);
            //   element.newDate = date.toLocaleDateString("en-US", {
            //     dateStyle: "medium",
            //   });
            // });
            // response.forEach((element, index) => {
            //   if (element?.createdAt) {
            //     const days = subDays(new Date(element?.createdAt), 0);
            //     arr[index] = days;
            //   }
            // });
            setData(response.data);
            // dispatch(setRequests(response));
            // setLoading(false);
        } catch (error) {
            console.log("Mood journal list error", error);
            // setLoading(false);
        }
    };
    // console.log(
    //   "dddddddd",
    //   new Date(data[data.length - 1]?.createdAt).toLocaleDateString()
    // );
    useEffect(() => {
        fetchFilterRequests();
    }, []);
    return (
        <div className="userWrapper">

            <Container>
                <div className="assesment-container">
                    <h3>This is not an emergency service</h3>
                    <div className="main-info">
                        <h5>
                            You must not use the service for emergencies or urgent conditions as this may delay neccessary treatment.
                        </h5>
                    </div>
                    <div className="main-info">
                        <p>
                            If you believe that you are in an emergency or life-threatening situation you should contact your local emergencies services immediately.
                        </p>
                        <p>
                            especially if someone:
                        </p>
                    </div>
                    <div className="points">
                        <p>
                            <img src={alert} /><span>has difficulty or is not breathing</span>
                        </p>
                        <p>
                            <img src={alert} /><span>you believe someone is having heart attack or stroke</span>
                        </p>
                        <p>
                            <img src={alert} /><span>has severe chest pain</span>
                        </p>
                        <p>
                            <img src={alert} /><span>has abdominal pain</span>
                        </p>
                        <p>
                            <img src={alert} /><span>has severe bleeding and it can't be stopped</span>
                        </p>
                        <p>
                            <img src={alert} /><span>has lost consiousness</span>
                        </p>
                        <p>
                            <img src={alert} /><span>is in an acute confused state and/or is having fits which aren't stopping</span>
                        </p>
                    </div>

                    <div className="main-info">
                        <p>
                            Only residents and/or nationals of the United Kingdom (UK) Republic of Ireland are ebligible to use our services. By using our services, you are confirming that you are a UK or Republic of Ireland resident and/or national.
                        </p>
                    </div>
                    <div className="main-info">
                        <p>
                            The service is not a replacement for your own GP. You may still need to see your own GP or contact the emergency sevices if the clinician you speak with feel its neccessary.
                        </p>
                    </div>
                    <Link to="/confirm-Remote-GP">
                        <Button name="I Understand" />
                    </Link>
                </div>
            </Container>
            <div className="partnerShip">
                <p>In partnership with</p>
                <img src={partnerLogo} />
            </div>
        </div>
    );
};

export default Disclaimer;

import React from "react";
import "./BorderButton.scss";

const Button = ({ name = "name", onClick }) => {
  return (
    <div className="button-border">
      <button className="btn site-border-btn" onClick={onClick}>{name}</button>
    </div>
  )
}

export default Button;
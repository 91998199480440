import "./HealthAssesmentSteps.scss";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AssesmentStepOne from '../HealthAssesmentSteps/AssesmentStepOne/AssesmentStepOne';
import AssesmentStepTwo from '../HealthAssesmentSteps/AssesmentStepTwo/AssesmentStepTwo';
import AssesmentStepThree from '../HealthAssesmentSteps/AssesmentStepThree/AssesmentStepThree';
import AssesmentStepFour from '../HealthAssesmentSteps/AssesmentStepFour/AssesmentStepFour';
import AssesmentStepFive from '../HealthAssesmentSteps/AssesmentStepFive/AssesmentStepFive';
import partnerLogo from "../../../assets/img/partner.svg";
import infoCircle from "../../../assets/img/info-circle.svg";
import FaAngleLeft from '../../../assets/img/angle-left.svg';

const HealthAssesmentSteps = ({ }) => {

	const [key, setKey] = useState('Step1');

	const handleOptionSelect = () => {
		key == 'Step1' ?
		setKey('Step2') : key == 'Step2' ? setKey('Step3') : key == 'Step3' ? setKey('Step4') : key == 'Step4' ? setKey('Step5') : setKey('Step5') 
	  };
	return (
		<div className="userWrapper assesmentWrapper">
			<div className="assesment-steps">
				<div className="assesment-steps_topbar">
					<Container>
						<div className="assesment-steps_topbar_inner">
							<Link to="/health-assesment">
								<h6>
									<img className="img-fluid me-2" src={FaAngleLeft} />
									<span>Health assessment</span>
								</h6>
							</Link>
							<Link to="/health-assesment" className="help-link">
								<img src={infoCircle} />
								<span>Help</span>
							</Link>
						</div>
					</Container>
				</div>
				<div className="assesment-steps_content">
					<Container>
						<Tabs
							id="controlled-tab-example"
							activeKey={key}
							onSelect={(k) => setKey(k)}
							className="mb-3"
						>
							<Tab eventKey="Step1" title="Section 1 of 5">
								<AssesmentStepOne handleOptionSelect={handleOptionSelect} />
							</Tab>
							<Tab eventKey="Step2" title="">
							   <AssesmentStepTwo handleOptionSelect={handleOptionSelect}/>
							</Tab>
							<Tab eventKey="Step3" title="">
							   <AssesmentStepThree handleOptionSelect={handleOptionSelect}/>
							</Tab>
							<Tab eventKey="Step4" title="">
							   <AssesmentStepFour handleOptionSelect={handleOptionSelect}/>
							</Tab>
							<Tab eventKey="Step5" title="">
							   <AssesmentStepFive handleOptionSelect={handleOptionSelect}/>
							</Tab>
						</Tabs>
					</Container>
				</div>
			</div>
			<div className="partnerShip">
				<p>In partnership with</p>
				<img src={partnerLogo} />
			</div>
		</div>
	);
};

export default HealthAssesmentSteps;

import "./dashboard.scss";
import Container from "react-bootstrap/Container";
import Button from "../../component/Button/Button";
import partnerLogo from "../../assets/img/partner.svg";
import health from "../../assets/img/health.svg";
import nurse from "../../assets/img/nurse.svg";
import mood from "../../assets/img/mood.svg";
import symptom from "../../assets/img/symptom.svg";
import remotePhysio from "../../assets/img/remotePhysio.svg";
import remoteGp from "../../assets/img/remoteGp.svg";
import { useEffect, useState } from "react";
import { userAxios } from "../../utils/config";
import axios from "axios";
import Loader from "../../component/Loader/Loader";
import { setProfile } from "../../Redux/Reducers/User";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../utils/config/axiosInstance";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = ({ }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");

  const getData = async () => {
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      // console.log("response dashboard ==>", response);
      setData(response.data);
      dispatch(setProfile(response.data));
    } catch (error) {
      console.log("eeror in dashboard", error);
      // console.log("eeror in dashboard", error);
    }
  };

  const birthdateFromRedux = data?.userInfo?.user_metadata?.birthdate;
  let birthdate = "";
  if (birthdateFromRedux) {
    const parts = birthdateFromRedux.split('/');
    const formattedBirthdate = `${parts[2]}-${parts[1].padStart(2, '0')}-${parts[0].padStart(2, '0')}`;

    // Update the birthdate in the Redux store or data object
    birthdate = formattedBirthdate;
  }

  const today = new Date();
  const formattedToday = today.toISOString().slice(0, 10);

  const remoteRedirect = async () => {
    setLoading(true);
    try {
      const responseIframe = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/phio/getaccess`,
        // {
        //   customer_name: "Medipartner",
        //   member: {
        //     first_name: data?.userInfo?.given_name,
        //     last_name: data?.userInfo?.family_name,
        //     dob: "1990-08-24",
        //     email: "daryn@medipartner.co.uk",
        //     phone: data?.userInfo?.user_metadata.phone_number,
        //     member_id: "1234567891",
        //     f2f_allowance: 0,
        //     membership_start_date: "2022-11-05",
        //     membership_end_date: "2023-11-04",
        //     address_line_1: ".",
        //     address_line_2: ".",
        //     address_line_3: ".",
        //     postcode: "DA6 8DS",
        //     policy_holder_name: "Post Office",
        //   },
        // }
        {
          patient: {
            first_name: data?.userInfo?.given_name,
            last_name: data?.userInfo?.family_name,
            dob: birthdate,
            post_code: "DA6 8DS",
            date_of_accident: formattedToday,
            email: data?.userInfo?.name,
            phone: data?.userInfo?.user_metadata?.phone_number,
            ip_reference: "84f4dddadyui78ifg65"
          },
          referee: {
            referee_id: "daryn@medipartner.co.uk",
            name: "Medipartner"
          }
        }
      );
      if (responseIframe.data.data.data.access_url) {
        window.open(responseIframe?.data?.data?.data?.access_url);
      }
      setLoading(false);
      navigate("/dashboard");
    } catch (error) {
      console.log("error-->", error);
      toast.error(error.response.data.message, { autoClose: 3000 });
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="userWrapper">
          <h2>Welcome back, {data?.userInfo?.given_name}!</h2>

          <p>
            Designed with your physical and mental wellbeing in mind. Select the
            service you require:
          </p>
          <Container>
            <div className="menu-column">
              <div className="row">
                {/* Temporary change */}
                {/* <div className="col-md-6 col-lg-4 custom-gutter">
                  <div className="menu-inner-data">
                    <div className="info">
                      <span className="icon">
                        <img src={health} />
                      </span>
                      <label className="menu-label">Health assessment</label>
                      <p className="custom-pad">
                        Our health assessments focus on preventative health,
                        informing you of potential issues and reinforcing good
                        behaviors to support your health.
                      </p>
                    </div>

                    <Button
                      name="Start assessment"
                      onClick={() => navigate("/health-assesment")}
                    />
                  </div>
                </div> */}
                <div className="col-md-6 col-lg-4 custom-gutter">
                  <div className="menu-inner-data">
                    <div className="info">
                      <span className="icon">
                        <img src={nurse} />
                      </span>
                      <label className="menu-label">Nurse champion</label>
                      <p>
                        One of our nursing team will help you navigate your
                        health & wellbeing journey with personalised advocacy,
                        guidance and support.
                      </p>
                    </div>

                    <Button
                      name="Book now"
                      onClick={() => navigate("/request-champion")}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 custom-gutter">
                  <div className="menu-inner-data">
                    <div className="info">
                      <span className="icon">
                        <img src={mood} />
                      </span>
                      <label className="menu-label">Mood journal</label>
                      <p>
                        Journaling your thoughts, emotions and challenges has
                        been shown to reduce anxiety and depression.
                      </p>
                    </div>

                    <Button
                      name="Add entry"
                      onClick={() => navigate("/mood-journal")}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 custom-gutter">
                  <div className="menu-inner-data">
                    <div className="info">
                      <span className="icon">
                        <img src={symptom} />
                      </span>
                      <label className="menu-label">Symptom checker</label>
                      <p className="custom-pad">
                      NHS 111, the online service can point you to the right service for you, based on your symptoms, whether that be to a GP, A&E or self-care.
                      </p>
                    </div>

                    <a href="https://111.nhs.uk/" target={"_blank"}>
                      <Button
                        name="Start assessment"
                      // onClick={() => navigate("/symptom-checker")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="row row-center">
                <div className="col-md-6 col-lg-4 custom-gutter">
                  <div className="menu-inner-data">
                    <div className="info">
                      <span className="icon">
                        <img src={remotePhysio} />
                      </span>
                      <label className="menu-label">Remote physio</label>
                      <p>
                        Find the right physiotherapy care for you. Complete the
                        digital assessment and if approved, access a tailored
                        exercise programme with in-app support.
                      </p>
                    </div>
                    <Button
                      name="Start assessment"
                      onClick={() => remoteRedirect()}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 custom-gutter">
                  <div className="menu-inner-data">
                    <div className="info">
                      <span className="icon">
                        <img src={remoteGp} />
                      </span>
                      <label className="menu-label">Remote GP</label>
                      <p>
                        Unlimited access to experienced GPs, at home, work,
                        whilst travelling or on holiday.
                      </p>
                    </div>
                    <a href={process.env.REACT_APP_REMOTE_GP_URL} target={"_blank"}>
                      <Button name="Start now" />
                    </a>
                  </div>

                </div>

                {/* Remote GP new flow */}
                {/* <div className="col-md-6 col-lg-4 custom-gutter">
                  <div className="menu-inner-data">
                    <div className="info">
                      <span className="icon">
                        <img src={remoteGp} />
                      </span>
                      <label className="menu-label">Remote GP New Flow</label>
                      <p>
                        Unlimited access to experienced GPs, at home, work,
                        whilst travelling or on holiday.
                      </p>
                    </div>
                    <Button
                      name="Book now"
                      onClick={() => navigate("/request-Remote-GP")}
                    />
                  </div>

                </div> */}

              </div>
            </div>
          </Container>
          <div className="partnerShip">
            <p>In partnership with</p>
            <img src={partnerLogo} />
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;

import "./new-password.scss";
import { Link, useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FaAngleLeft from "../../../../assets/img/angle-left.svg";
import question from "../../../../assets/img/question.svg";
import Button from "../../../../component/Button/Button";
import { FaCheckCircle, FaInfo } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { userAxios } from "../../../../utils/config";
import partnerLogo from "../../../../assets/img/partner.svg";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { type } from "@testing-library/user-event/dist/type";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

const NewPassword = ({}) => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState();
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [capital, setCapital] = useState(false);
  const [number, setNumber] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [length, setLength] = useState(false);
  const [type, setType] = useState("password");
  const [type2, setType2] = useState("password");
  const userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const email = sessionStorage.getItem("email");
  const [renderVerify, setRenderVerify] = useState(false)

  const checkPassword = (str) => {
    let errors = [];
    if (str.length >= 12) {
      setLength(true);
      // console.log("greater than 12");
      errors.push("Your password must be at least 8 characters");
    } else {
      setLength(false);
    }
    if (str.search(/^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/) >= 0) {
      setSymbol(true);
      // console.log("symbol ");
      errors.push("Your password must contain at least one letter.");
    } else {
      setSymbol(false);
    }

    if (str.search(/[0-9]/) >= 0) {
      // console.log("STRRR search", str.search(/[0-9]/));
      setNumber(true);
      // console.log("number ");
      errors.push("Your password must contain at least one digit.");
    } else {
      setNumber(false);
    }
    // if (errors.length > 0) {
    //   return false;
    // }
    // return true;
    if (str.search(/[A-Z]/) >= 0) {
      setCapital(true);
      // console.log("capital letter");
      errors.push("Your password must contain at least one letter.");
    } else {
      setCapital(false);
    }
  };

  const verifyCode = async() => {
    if (formData.password === formData.confirmPassword) {
      if (capital && number && length && symbol) {
    // setRenderVerify(true);
    try {
      const response = await userAxios.post(
        `create-otp`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if(response) {
        toast.success(response.data.message, {
          autoClose: 3000,
        });
        navigate("/account/security/new-password/verify-otp", {state: {formData}})
      }
    } catch (error) {
      console.error(error);
    }
  } else {
    toast.error("Please fulfill all criteria of password");
  }
} else {
    toast.error("Password does not match");
    setPasswordMatch(false);
  }
  }

  const changePassword = async () => {
    if (formData.password && formData.confirmPassword) {
      if (formData.password === formData.confirmPassword) {
        if (capital && number && length && symbol) {
          try {
            const response = await userAxios.put(
              `changepassword/${userId}`,
              {
                password: formData?.password,
                // email: email,
                // currentPassword: formData?.currentPassword
              },
              {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              }
            );
            // console.log("Respponse ==>", response);
            if (response) {
              navigate("/account/security");
              toast.success("Profile updated successfully", {
                autoClose: 3000,
              });
            }
          } catch (error) {
            toast.error("Something went wrong");
          }
        } else {
          toast.error("Please fulfill all criteria of password");
        }
      } else {
        toast.error("Password does not match");
        setPasswordMatch(false);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  return (
    <div className="userWrapper">
      <Form className="formContainer">
        <Link to="/account/security">
          <h3>
            <img className="img-fluid me-2" src={FaAngleLeft} />
            New password
          </h3>
        </Link>
        <hr />
        <Row>
          {/* <Col xs={12}>
            <Form.Group className="formMargin">
              <Form.Label>
                <span>Current password</span>
                <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Help</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder=""
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
          </Col> */}
          <Col xs={12}>
            {/* For current password */}
            {/* <Form.Group className="formMargin">
  <Form.Label>
    <span>Current password</span>
    <span>
      <img src={question} />
    </span>
  </Form.Label>
  <div className="password-main-div">
    <Form.Control
      type={type}
      placeholder=""
      value={formData.currentPassword}
      onChange={(e) => {
        setFormData({ ...formData, currentPassword: e.target.value });
        // Additional logic for current password validation if needed
      }}
    />
    <div
      className="eye-icon"
      onClick={() => {
        if (type === "password") {
          setType("text");
        } else {
          setType("password");
        }
      }}
    >
      {type !== "password" ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
    </div>
  </div>
</Form.Group> */}
          </Col>
          <Col xs={12}>
            <Form.Group className="formMargin">
              <Form.Label>
                <span>New password</span>
                <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Enter memorable password.</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
              </Form.Label>
              <div className="password-main-div">
                <Form.Control
                  type={type}
                  placeholder=""
                  value={formData.password}
                  onChange={(e) => {
                    setFormData({ ...formData, password: e.target.value });
                    checkPassword(e.target.value);
                  }}
                />
                <div
                  className="eye-icon"
                  onClick={() => {
                    if (type == "password") {
                      setType("text");
                    } else setType("password");
                  }}
                >
                  {type != "password" ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}
                </div>
              </div>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="formMargin">
              <Form.Label>
                <span>Re-type new password</span>
                <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Confirm new password.</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
              </Form.Label>
              <div className="password-main-div">
                <Form.Control
                  type={type2}
                  placeholder=""
                  value={formData.confirmPassword}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      confirmPassword: e.target.value,
                    });
                    if (e.target.value !== formData.password)
                      setPasswordMatch(false);
                    else setPasswordMatch(true);
                  }}
                />
                <div
                  className="eye-icon"
                  onClick={() => {
                    if (type2 == "password") {
                      setType2("text");
                    } else setType2("password");
                  }}
                >
                  {type2 != "password" ? (
                    <AiOutlineEye />
                  ) : (
                    <AiOutlineEyeInvisible />
                  )}
                </div>
              </div>
              {!passwordMatch && (
                <div className="text-danger mt-2">
                  Passwords are not matching
                </div>
              )}
            </Form.Group>
          </Col>
          <Col xs={12}>
            <div className="password-condtion">
              <p>Passwords must include at least:</p>
              <p className={length ? "active" : ""}>
                <FaCheckCircle />
                12 characters
              </p>
              <p className={capital ? "active" : ""}>
                <FaCheckCircle />1 capital letter
              </p>
              <p className={number ? "active" : ""}>
                <FaCheckCircle />1 number
              </p>
              <p className={symbol ? "active" : ""}>
                <FaCheckCircle />1 special symbol
              </p>
            </div>
          </Col>
        </Row>
      </Form>

      <Button name="Save" type="button" onClick={() => verifyCode()} disabled={capital && number && length && symbol && formData.password === formData.confirmPassword ? false : true}/>
      <div className="partnerShip">
        <p>In partnership with</p>
        <img className="img-fluid" src={partnerLogo} />
      </div>
    </div>
  );
};

export default NewPassword;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  requestEntries: [],
  requestID: "",
  nurseMessage: false,
  date: "",
};

const nurseChampionSlice = createSlice({
  name: "Nurse Champion",
  initialState,
  reducers: {
    setRequests: (state, action) => {
      state.requestEntries = [...action.payload];
    },
    setID: (state, action) => {
      state.requestID = action.payload;
    },
    prevData: (state, action) => {
      state.requestID = action.payload;
    },
    nextData: (state, action) => {
      state.requestID = action.payload;
    },
    setNurseMessage: (state, action) => {
      state.nurseMessage = action.payload.nurseMessage;
      state.date = action.payload.date;
    },
  },
});

export const { setRequests, setID, prevData, nextData, setNurseMessage } =
  nurseChampionSlice.actions;
export default nurseChampionSlice.reducer;

import React from "react";
import "./Button.scss";

const Button = ({ name = "name", type = "button", onClick, disabled }) => {
  return (
    <div className="button-container">
      <button
        type={type}
        className={`btn site-primary-btn`}
        disabled={disabled}
        onClick={onClick}
      >
        {name}
      </button>
    </div>
  );
};

export default Button;

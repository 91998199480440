import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userProfile: {},
};

const userProfileSlice = createSlice({
  name: "User Data",
  initialState,
  reducers: {
    setProfile: (state, action) => {
        state.userProfile = action.payload;
    },
  },
});

export const { setProfile } =
  userProfileSlice.actions;
export default userProfileSlice.reducer;
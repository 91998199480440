import React, { createContext, useReducer, useContext } from "react";

const Data = createContext();

const dataReducer = (state, { type, payload }) => {
  // console.log("from action", type, payload);
  switch (type) {
    case 1: {
      return {
        ...state,
        data: payload,
      };
    }
    default: {
      return state;
    }
  }
};
const versionReducer = (state, { type, payload }) => {
  switch (type) {
    case 1: {
      return {
        ...state,
        data: payload,
      };
    }
    default: {
      return state;
    }
  }
};

const modalReducer = (state, { type, payload }) => {
  // console.log("from action", type, payload);
  switch (type) {
    case 1: {
      return {
        ...state,
        data: payload,
      };
    }
    default: {
      return state;
    }
  }
};
const loaderReducer = (state, { type, payload }) => {
  // console.log("from action", type, payload);
  switch (type) {
    case 1: {
      return {
        ...state,
        data: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const DataProvider = ({ children }) => {
  const [dataState, dataDispatch] = useReducer(dataReducer, {
    data: "data",
  });
  const [modalOpenState, modalOpenDispatch] = useReducer(modalReducer, {
    data: false,
  });
  const [versionState, versionDispatch] = useReducer(versionReducer, {
    data: "0.0",
  });
  const [loadingState, loadingDispatch] = useReducer(loaderReducer, {
    data: false,
  });
  return (
    <Data.Provider
      value={{
        loadingState,
        loadingDispatch,
        dataState,
        dataDispatch,
        modalOpenState,
        modalOpenDispatch,
        versionState,
        versionDispatch,
      }}
    >
      {children}
    </Data.Provider>
  );
};
// const first = useContext(second);
export const DataStore = () => useContext(Data);

import "./AssesmentStepFive.scss";
import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import FaAngleLeft from '../../../../assets/img/angle-left.svg';
import confirm from '../../../../assets/img/confirm.svg';
import confirmBanner from '../../../../assets/img/step-banner.png';
import Button from "../../../../component/Button/Button";

const AssesmentStepFive = ({ }) => {
	const navigate = useNavigate();
	const handleSubmit = () => {
		navigate("/dashboard");
	}
	return (
		<div className="assesment-steps_marking">
			{/* <div className="assesment-steps_marking_inner">
				<h2>Sleep</h2>
				<p>How many hours of sleep do you usually get? </p>
				<p>Do you snore or has anyone told you that you snore?</p>
				<div className="assesment-steps_marking_inner_options">
					<Link className="option-row">
						<span>0 - 3</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row">
						<span>4 - 6</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row">
						<span>7 - 10</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row">
						<span>10 +</span>
						<img src={FaAngleLeft} />
					</Link>
				</div>
			</div> */}

			{/* Last Step */}
			<div className="assesment-steps_marking_inner">
				<div className="steps-submitted">
					<div className="steps-submitted_inform">
						<img src={confirm} />
						<span>Ready to Submit</span>
					</div>
					<div className="steps-submitted_banner">
						<img src={confirmBanner} className="img-fluid" />
					</div>
					<div className="steps-submitted_description">
						<p>Congratulations, you are done! </p>
						<p>
							Once you press save we will submit your responses for review. You will receive your  report and advice back in this app within the next few days.
						</p>
					</div>
				</div>
				<Button name="Save" onClick={handleSubmit} />
			</div>
		</div>
	);
};

export default AssesmentStepFive;

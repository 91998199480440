import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import "react-datepicker/dist/react-datepicker.css";
// import * as swDev from "./swDev";
import swDev from "./swDev";

// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import ScrollToTop from "./component/ScrollToTop";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <ScrollToTop /> */}
    <App />
  </Provider>
  // </React.StrictMode>
);
// swDev.register();
swDev();

// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

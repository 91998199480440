import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import Loader from "../../../component/Loader/Loader";
import { nextData, prevData } from "../../../Redux/Reducers/MoodJournal";
import { userAxios } from "../../../utils/config";
import "./JournalEnteriesPrev.scss";
import partnerLogo from "../../../assets/img/partner.svg";

const SingleEntryPrev = () => {
  const dispatch = useDispatch();
  const selectorData = useSelector((store) => store.moodJournal);
  const data = selectorData.journalEntries[selectorData.journalID];
  const navigate = useNavigate();
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(data?.createdAt);
  const newDate = date.toLocaleString("en-US", options);

  let userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  const getData = async () => {
    setLoading(true);
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setUserData(response?.data?.userInfo);
      // console.log(response?.data?.userInfo);
      setLoading(false);
    } catch (error) {
      // console.log("eeror in dashboard", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectorData?.journalEntries.length == 0 ||
      selectorData?.journalID === ""
    )
      navigate("/journal-entry-prev");
    else getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : selectorData?.journalEntries.length == 0 ||
        selectorData?.journalID === "" ? (
        <></>
      ) : (
        <div className="commonWrapper">
          <Link to={"/journal-entry-prev"}>
            <h3>
              <img className="img-fluid me-2" src={FaAngleLeft} />
              <div className="heading">
                <span>{userData?.given_name ?? "User"}’s journal entry</span>
                <span>{newDate}</span>
                {/* <span>Sept, 17 2022</span> */}
              </div>
            </h3>
          </Link>
          <hr />
          <div className="mood-selction-info mt-32">
            <p className="selected-option mb-48">
              <img
                src={
                  process.env.REACT_APP_API_URL_DEVELOPMENT +
                  data?.moodsss?.avatar
                }
              />
              <span>
                I feel{" "}
                <span className="text-lowercase">{data?.moodsss?.value}</span>{" "}
                today.
              </span>
            </p>
            <div className="selected-option-info mb-48">
              <label>Journal entry</label>
              {/* <p>
            Not sure why I feel sad, nothing has happened to cause that I can
            think of. It is just a feeling.{" "}
          </p> */}
              <p>{data?.message}</p>
            </div>
            <div className="sliding-btns d-flex align-items-center">
              <div className="col-10">
                {selectorData?.journalID != 0 && (
                  <Link>
                    <span
                      onClick={() => {
                        if (selectorData?.journalID != 0)
                        dispatch(prevData(selectorData.journalID - 1));
                      window.scrollTo(0, 0);
                    }}
                    >
                    <img className="faLeftclass" src={FaAngleLeft} />
                      Previous
                    </span>
                  </Link>
                )}
              </div>
              <div className="col-2">
                {selectorData?.journalID !=
                  selectorData.journalEntries.length - 1 && (
                  <Link>
                    <span
                      onClick={() => {
                        if (
                          selectorData?.journalID !=
                          selectorData.journalEntries.length - 1
                        )
                          dispatch(nextData(selectorData.journalID + 1));
                        window.scrollTo(0, 0);
                      }}
                    >
                      Next
                    <img src={FaAngleLeft} alt="Left Arrow" />
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="text-center partnerWith">
            <p>In partnership with</p>
            <img className="img-fluid" src={partnerLogo} />
          </div>
        </div>
      )}
    </>
  );
};

export default SingleEntryPrev;

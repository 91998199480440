import { decryptResponse, encryptResponse } from "../cryptoutils";
import CryptoJS from "crypto-js";

import axios from "axios";
// authUser
export const axiosInstance = axios.create();

// Add a response interceptor
axiosInstance.interceptors.response.use((response) => {
  const accessToken = sessionStorage.getItem("accessToken");
  const decryptedResponse = decryptResponse(response.data, accessToken);
  response.data = decryptedResponse;
  return response;
}, 
(error) => {
  if (error.response && error.response.data) {
    const accessToken = sessionStorage.getItem("accessToken");
    const decryptedError = decryptResponse(error.response.data, accessToken);
    error.response.data = decryptedError;
  }
  return Promise.reject(error);
});

// Add a request interceptor
axiosInstance.interceptors.request.use((config) => {
  const accessToken = sessionStorage.getItem("accessToken");

    // Encrypt the access token using AES encryption
     config.headers["X-Access-Token"] = accessToken;
  const encryptedPayload = encryptResponse(JSON.stringify(config.data),accessToken);
  const encodedPayload = encodeURIComponent(encryptedPayload);
  config.data = `encryptedPayload=${encodedPayload}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, AUTH0_USER_SCOPE } from "../config";
import auth0 from "auth0-js";

const auth = new auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN,
  // clientID: AUTH0_CLIENT_ID,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  scope: AUTH0_USER_SCOPE,
});

const auth0Manage =
  sessionStorage.getItem("accessToken") &&
  new auth0.Management({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    clientID: "p5haXPMRYRsUApBCxgzAXt64SPEG8PU5",
    token: sessionStorage.getItem("accessToken"),
    scope:
      "update:users, update:users_app_metadata,update:current_user_metadata",
  });


export { auth, auth0Manage };

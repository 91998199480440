import "./Register.scss";
import Form from "react-bootstrap/Form";
import Button from "../../component/Button/Button";
import BorderButton from "../../component/BorderButton/BorderButton";
import partnerLogo from "../../assets/img/partner.svg";
import question from "../../assets/img/question.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik, useFormik, Form as FormikForm, ErrorMessage } from "formik";
import { SignUpSchema } from "../../helper/Validation";
import { userAxios } from "../../utils/config";
import { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { DataStore } from "../../store";
import { type } from "@testing-library/user-event/dist/type";
import Loader from "../../component/Loader/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";

const VerifyCode = () => {
  const [code, setCode] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
    sixth: "",
  });
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const [loading, setLoading] = useState();
  const firstInputRef = useRef(null);


  const { dataState } = DataStore();
  const navigate = useNavigate();
  // console.log("Datat state in verifu code=>", dataState);
  const email = dataState?.data?.email;

  // const nextInput = (e) => {
  //   let mykey = "0123456789".split("");
  //   console.log("Clicked", e);
  //   // let current = e.target;
  //   // let index = parseInt(current.classList[0].split("_")[2]);
  //   // console.log("Clicked index ==>", index);
  //   // current.value = e.key;
  //   // if (e.keyCode == 8 && index > 1) {
  //   //   current.previousElementSibiling.focus();
  //   // }
  //   // if (index < 6 && mykey.indexOf("" + e.key + "") != -1) {
  //   //   var next = current.nextElementSibling;
  //   //   next.focus();
  //   // }
  // };

  let digitValidate = function (ele) {
    // console.log(ele.value);
    ele.value = ele.value.replace(/[^0-9]/g, "");
  };

  // let tabChange = function (val) {
  //   let ele = document.querySelectorAll("input");
  //   if (ele[val - 1].value != "") {
  //     ele[val].focus();
  //   } else if (ele[val - 1].value == "") {
  //     ele[val - 2].focus();
  //   }
  // };

  let tabChange = function (val) {
    let ele = document.querySelectorAll("input");
    if (ele[val - 1].value != "") {
      if (ele[val]) {
        ele[val].focus();
      }
    } else if (ele[val - 1].value == "" && ele[val - 2]) {
      ele[val - 2].focus();
    }
  };

  const handleCode = async () => {
    setLoading(true);

    // console.log("values==>", { ...code });
    const { first, second, third, fourth, fifth, sixth } = code;

    if (first && second && third && fourth && fifth && sixth) {
      const otp = first + second + third + fourth + fifth + sixth;
      // console.log("aaa==>", otp);
      try {
        const response = await userAxios.post("/register/verify", {
          otp,
          email,
        });
        if (response) {
          // toast.success("Verified successfully", { autoClose: 3000 });
          navigate("/register/create-password");
        }
        // console.log("response ==>", response);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        // console.log("Elseee");
        toast.error(error.response.data.message, { autoClose: 3000 });
        setLoading(false);
      }
    } else {
      // console.log("Elseee");
      toast.error("Please enter correct code", { autoClose: 3000 });
    }
    setLoading(false);
  };


  const handlePaste = (e) => {
    const pastedData = e.clipboardData.getData('text').trim();
    
    if (pastedData.length === 6 && /^[0-9]+$/.test(pastedData)) {
      const newCode = {
        first: pastedData[0],
        second: pastedData[1],
        third: pastedData[2],
        fourth: pastedData[3],
        fifth: pastedData[4],
        sixth: pastedData[5],
      };
      
      setCode(newCode);
       const nonEmptyInputIndex = Object.values(newCode).findIndex(val => val !== '');
      if (nonEmptyInputIndex !== -1 && inputRefs[nonEmptyInputIndex].current) {
        inputRefs[nonEmptyInputIndex].current.focus();
      }
    } else {
      e.preventDefault();
      toast.error('Please paste a valid 6-digit code.', { autoClose: 3000 });
    }
  };

  useEffect(() => {
    if (!dataState?.data?.firstName) {
      navigate("/register");
    }
  }, []);

  useEffect(() => {
    if (firstInputRef.current) {
      firstInputRef.current.focus(); // Set focus on the first input element
    }
  }, []);

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <div className="userWrapper">
          {/* <ToastContainer /> */}
          <div className="twoStep">
            <h2>Thank you for signing up, {dataState?.data?.firstName}!</h2>
            <p>
              Please enter the 6 digit below that we have sent to your email
              address.
            </p>
            <Form className="formContainer">
              <div className="form-label label">
                <span>Verification code</span>
                <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip>Enter 6 digit code.</Tooltip>}
                    >
                    <span>
                      <img src={question} alt="question" />
                    </span>
                    </OverlayTrigger>
              </div>
              <Form.Group className="formMargin" controlId="formBasicPassword">
                <div className="row">
                  <div className="col-2">
                    <Form.Control
                      type="text"
                      value={code.first}
                      maxLength={1}
                      onChange={(e) =>
                        setCode({ ...code, first: e.target.value })
                      }
                      className="otp_field_1 text-center"
                      onKeyUp={(e) => tabChange(1)}
                      onInput={(e) => digitValidate(e.target)}
                      ref={firstInputRef} 
                      onPaste={handlePaste}
                    />
                  </div>
                  <div className="col-2">
                    <Form.Control
                      type="text"
                      maxLength={1}
                      value={code.second}
                      onChange={(e) =>
                        setCode({ ...code, second: e.target.value })
                      }
                      className="otp_field_2 text-center"
                      onKeyUp={(e) => tabChange(2)}
                      onInput={(e) => digitValidate(e.target)}
                    />
                  </div>
                  <div className="col-2">
                    <Form.Control
                      type="text"
                      maxLength={1}
                      value={code.third}
                      onChange={(e) =>
                        setCode({ ...code, third: e.target.value })
                      }
                      className="otp_field_3 text-center"
                      onKeyUp={(e) => tabChange(3)}
                      onInput={(e) => digitValidate(e.target)}
                    />
                  </div>
                  <div className="col-2">
                    <Form.Control
                      type="text"
                      maxLength={1}
                      value={code.fourth}
                      onChange={(e) =>
                        setCode({ ...code, fourth: e.target.value })
                      }
                      className="otp_field_4 text-center"
                      onKeyUp={(e) => tabChange(4)}
                      onInput={(e) => digitValidate(e.target)}
                    />
                  </div>
                  <div className="col-2">
                    <Form.Control
                      type="text"
                      maxLength={1}
                      value={code.fifth}
                      onChange={(e) =>
                        setCode({ ...code, fifth: e.target.value })
                      }
                      className="otp_field_5 text-center"
                      onKeyUp={(e) => tabChange(5)}
                      onInput={(e) => digitValidate(e.target)}
                    />
                  </div>
                  <div className="col-2">
                    <Form.Control
                      type="text"
                      maxLength={1}
                      value={code.sixth}
                      onChange={(e) =>
                        setCode({ ...code, sixth: e.target.value })
                      }
                      className="otp_field_6 text-center"
                      onKeyUp={(e) => tabChange(6)}
                      onInput={(e) => digitValidate(e.target)}
                      onKeyDown={(e) => {if (e.key === "Enter") {
                        e.preventDefault();
                        handleCode()
                      }}}
                    />
                  </div>
                </div>
              </Form.Group>
            </Form>
            <Button
              name="Confirm"
              type="button"
              onClick={() => {
                handleCode();
              }}
            />
            <div className="partnerShip">
              <p>In partnership with</p>
              <img src={partnerLogo} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerifyCode;

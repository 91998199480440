import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

const LogoutTimer = ({ timeout }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    let sessionTimeout;

    const resetTimeout = () => {
      clearTimeout(sessionTimeout);
      sessionTimeout = setTimeout(() => {
        setIsSessionExpired(true);
        window.location.href = '/';
        sessionStorage.clear();
        toast.error('Session expired. Please log in again.'); 
      }, timeout);
    };

    const handleUserActivity = () => {
      resetTimeout();
    };

    const eventListeners = ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll'];

    eventListeners.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });

    resetTimeout();

    return () => {
      clearTimeout(sessionTimeout);
      eventListeners.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
    };
  }, [timeout]);

  return (
    <div>
      {isSessionExpired}
    </div>
  );
};

export default LogoutTimer;
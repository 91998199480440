import "./documents.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import downloadImg from "../../../assets/img/download.svg";
import partnerLogo from "../../../assets/img/partner.svg";

const Documents = ({}) => {
  return (
    <div className="userWrapper">
      <div className="commonWrapper about-page">
        <Link to="/account">
          <h3>
            <img className="img-fluid me-2" src={FaAngleLeft} />
            Your documents
          </h3>
        </Link>
        <hr />
        <h4 className="mb-32 mt-8">
          <strong>Membership documents</strong>
        </h4>
        <p className="mb-32">
          The Key Facts Document contains a summary of the policy, including its
          key features, benefits, exclusions and limitations. Please ensure you
          read this document carefully.
        </p>
        <div className="outer-link">
          <Link to="/">
            <img className="img-fluid me-2" src={downloadImg} />
            IPD document
          </Link>
          <Link to="/">
            <img className="img-fluid me-2" src={downloadImg} />
            Key facts document
          </Link>
          <Link to="/">
            <img className="img-fluid me-2" src={downloadImg} />
            Real cover membership guide
          </Link>
        </div>
      </div>
      <div className="partnerShip">
        <p>In partnership with</p>
        <img className="img-fluid" src={partnerLogo} />
      </div>
    </div>
  );
};

export default Documents;

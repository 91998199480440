import "./AssesmentStepOne.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from '../../../../assets/img/angle-left.svg';


const AssesmentStepOne = ({ handleOptionSelect }) => {

	return (
		<div className="assesment-steps_marking">
			<div className="assesment-steps_marking_inner">
			  <h2>General health</h2>
			  <p>In general, would you say your health is:</p>
			  <div className="assesment-steps_marking_inner_options">
				<Link className="option-row" onClick={handleOptionSelect}>
					<span>Very good</span>
					<img src={FaAngleLeft} />	
				</Link>
				<Link className="option-row" onClick={handleOptionSelect}>
					<span>Fair</span>
					<img src={FaAngleLeft} />	
				</Link>
				<Link className="option-row" onClick={handleOptionSelect}>
					<span>Poor</span>
					<img src={FaAngleLeft} />	
				</Link>
				<Link className="option-row" onClick={handleOptionSelect}>
					<span>I don’t know</span>
					<img src={FaAngleLeft} />	
				</Link>
			  </div>
			</div>
		</div>
	);
};

export default AssesmentStepOne;

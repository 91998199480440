import "./AssesmentStepTwo.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from '../../../../assets/img/angle-left.svg';

const AssesmentStepTwo = ({ handleOptionSelect }) => {

	return (
		<div className="assesment-steps_marking">
			<div className="assesment-steps_marking_inner">
				<h2>Nutrition</h2>
				<p>Do you eat at least 3 dairy product servings daily or do you take a calcium supplement?</p>
				{/* <p>Do you routinely eat fatty fish such as salmon or tuna or take a vitamin D supplement containing at least 800 IUs of vitamin D per day?</p> */}
				<div className="assesment-steps_marking_inner_options">
					<Link className="option-row" onClick={handleOptionSelect}>
						<span>Yes</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row" onClick={handleOptionSelect}>
						<span>No</span>
						<img src={FaAngleLeft} />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AssesmentStepTwo;

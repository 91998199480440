import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FaAngleLeft from "../../../assets/img/angle-left.svg";
import partnerLogo from "../../../assets/img/partner.svg";
import "./JournalEntry.scss";
import AfterEntry from "./AfterEntry";
import SelectEmoji from "./SelectEmoji";
import WriteEntry from "./WriteEntry";
import { userAxios } from "../../../utils/config";

const JournalEntry = ({}) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState("");
  let userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  var date = new Date();
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const todayDate = date.toLocaleString("en-US", options);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await userAxios.get(`getuser/${userId}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setUserData(response?.data?.userInfo);
      // console.log(response?.data?.userInfo);
      setLoading(false);
    } catch (error) {
      // console.log("eeror in dashboard", error);
      setLoading(false);
    }
  };
  // console.log("step====>", step);
  useEffect(() => {
    getData();
  }, []);
  // console.log("data---->", data);
  return (
    <div className="userWrapper">
      <div className="commonWrapper">
        <div
          role="button"
          onClick={() => {
            step == 1 ? navigate("/mood-journal") : setStep(step - 1);
          }}
        >
          <h3>
            <img className="img-fluid me-2" src={FaAngleLeft} />
            <div className="heading">
              <span>{userData?.given_name ?? "User"}’s journal entry</span>
              <span>{todayDate}</span>
            </div>
          </h3>
        </div>
        <hr />

        {/* Select emoji according to mood */}
        {step == 1 && <SelectEmoji setStep={setStep} setData={setData} />}

        {/* Write your jounal entry */}
        {step == 2 && (
          <WriteEntry data={data} setStep={setStep} setData={setData} />
        )}

        {/* After jounal entry */}
        {step == 3 && <AfterEntry data={data} />}
      </div>
      <div className="partnerShip">
        <p>In partnership with</p>
        <img src={partnerLogo} />
      </div>
    </div>
  );
};

export default JournalEntry;

import "./AssesmentStepFour.scss";
import { Link } from "react-router-dom";
import FaAngleLeft from '../../../../assets/img/angle-left.svg';

const AssesmentStepFour = ({ handleOptionSelect}) => {

	return (
		<div className="assesment-steps_marking">
			<div className="assesment-steps_marking_inner">
				<h2>Physical activity</h2>
				<p>How many days a week do you do some physical activity? (Walking, jogging, running, cycling, sports) </p>
				{/* <p>On the days that you exercised, how long did you do physical activities?</p> */}
				<div className="assesment-steps_marking_inner_options">
					<Link className="option-row" onClick={handleOptionSelect}>
						<span>None</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row" onClick={handleOptionSelect}>
						<span>1 - 2</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row" onClick={handleOptionSelect}>
						<span>3 - 4</span>
						<img src={FaAngleLeft} />
					</Link>
					<Link className="option-row" onClick={handleOptionSelect}>
						<span>5 +</span>
						<img src={FaAngleLeft} />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AssesmentStepFour;

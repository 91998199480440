import React, { useState } from "react";
import Button from "../../../component/Button/Button";
import angry from "../../../assets/img/angry.svg";
import annoyed from "../../../assets/img/annoyed.svg";
import bored from "../../../assets/img/bored.svg";
import confused from "../../../assets/img/confused.svg";
import depressed from "../../../assets/img/depresed.svg";
import hurted from "../../../assets/img/hurted.svg";
import love from "../../../assets/img/love.svg";
import nervous from "../../../assets/img/nervous.svg";
import sad from "../../../assets/img/sad.svg";
import sick from "../../../assets/img/sick.svg";
import sleeping from "../../../assets/img/sleeping.svg";
import smile from "../../../assets/img/smile.svg";
import socked from "../../../assets/img/socked.svg";
import thinking from "../../../assets/img/thinking.svg";
import unhappy from "../../../assets/img/unhappy.svg";

const SelectEmoji = (props) => {
  const { setStep, setData } = props;
  const [mood, setMood] = useState({});
  const emojiData = [
    { id: "angry", name: "Angry", src: angry },
    { id: "bored", name: "Bored", src: bored },
    { id: "nervous", name: "Anxious", src: nervous },
    { id: "sad", name: "Sad", src: sad },
    { id: "sick", name: "Sick", src: sick },
    { id: "smile", name: "Happy", src: smile },
    { id: "socked", name: "Shocked", src: socked },
    { id: "thinking", name: "Thoughtful", src: thinking },
    { id: "annoyed", name: "Annoyed", src: annoyed },
    { id: "confused", name: "Confused", src: confused },
    { id: "depressed", name: "Depressed", src: depressed },
    { id: "hurted", name: "Hurting", src: hurted },
    { id: "love", name: "In Love", src: love },
    { id: "sleeping", name: "Tired", src: sleeping },
    { id: "unhappy", name: "Unhappy", src: unhappy },
  ];

  return (
    <div className="moods-list-info mt-8">
      <h2 className="mb-32">Which icon best expresses your current mood?</h2>
      <section className="moods-category">
        {emojiData.map((item, index) => (
          <div key={index} className="mood-option">
            <input
              type="radio"
              value={index}
              name="emoji"
              id={item.id}
              onChange={(e) =>
                setMood({
                  ...mood,
                  moodId: e.target.value,
                  moodName: item?.name,
                  moodImage: item?.src,
                })
              }
            />
            <label htmlFor={item.id}>
              <img src={item.src} />
              <span>{item.name}</span>
            </label>
          </div>
        ))}
      </section>
      {/* <section className="moods-category">
        <div className="mood-option">
          <input type="radio" value={"1"} name="emoji" id="angry" />
          <label htmlFor="angry">
            <img src={angry} />
            <span>Angry</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"2"} name="emoji" id="bored" />
          <label htmlFor="bored">
            <img src={bored} />
            <span>Bored</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"3"} name="emoji" id="nervous" />
          <label htmlFor="nervous">
            <img src={nervous} />
            <span>Anxious</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"4"} name="emoji" id="sad" />
          <label htmlFor="sad">
            <img src={sad} />
            <span>Sad</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"5"} name="emoji" id="sick" />
          <label htmlFor="sick">
            <img src={sick} />
            <span>Sick</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"6"} name="emoji" id="smile" />
          <label htmlFor="smile">
            <img src={smile} />
            <span>Happy</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"7"} name="emoji" id="socked" />
          <label htmlFor="socked">
            <img src={socked} />
            <span>Shocked</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"8"} name="emoji" id="thinking" />
          <label htmlFor="thinking">
            <img src={thinking} />
            <span>Thoughtful</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"9"} name="emoji" id="annoyed" />
          <label htmlFor="annoyed">
            <img src={annoyed} />
            <span>Annoyed</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"10"} name="emoji" id="confused" />
          <label htmlFor="confused">
            <img src={confused} />
            <span>Confused</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"11"} name="emoji" id="depressed" />
          <label htmlFor="depressed">
            <img src={depressed} />
            <span>Depressed</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"12"} name="emoji" id="hurted" />
          <label htmlFor="hurted">
            <img src={hurted} />
            <span>Hurting</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"13"} name="emoji" id="love" />
          <label htmlFor="love">
            <img src={love} />
            <span>In Love</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"14"} name="emoji" id="sleeping" />
          <label htmlFor="sleeping">
            <img src={sleeping} />
            <span>Tired</span>
          </label>
        </div>
        <div className="mood-option">
          <input type="radio" value={"15"} name="emoji" id="unhappy" />
          <label htmlFor="unhappy">
            <img src={unhappy} />
            <span>Unhappy</span>
          </label>
        </div>
      </section> */}
      <Button
        name="Continue"
        disabled={!mood?.moodId}
        onClick={() => {
          setStep(2);
          setData({ ...mood });
          window.scrollTo(0, 0);
        }}
      />
    </div>
  );
};

export default SelectEmoji;

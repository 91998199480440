import React from "react";
import { Link } from "react-router-dom";
import mood from "../../../assets/img/mood.svg";
import psycho from "../../../assets/img/psycho.svg";
import Button from "../../../component/Button/Button";

const AfterEntry = (props) => {
  const { data } = props;
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date(data?.date);
  var num = date.getDate() + "";
  switch (num[num.length - 1]) {
    case "1":
      num = num + "st";
      break;
    case "2":
      num = num + "nd";
      break;
    case "3":
      num = num + "rd";
      break;

    default:
      num = num + "th";
      break;
  }
  return (
    <div className="assesment-container">
      <div className="main-info journal-info">
        <p>
          Your journal entry for{" "}
          {weekday[date.getDay()] + " " + num + " " + months[date.getMonth()]}{" "}
          has been submitted.
        </p>
        <img src={mood} />
      </div>
      <div className="bottom-btn-group">
        <Link to="/journal-entry-prev" className="w-100">
          <Button name="View previous journal entries" />
        </Link>
        <Link to="/help-crisis" className="w-100">
          <button className="red-border-btn">
            <span>In a mental health crisis?</span>
            <img src={psycho} />
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AfterEntry;

import axios from "axios";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "../../../component/Button/Button";
import "./JournalEntry.scss";
import { axiosInstance } from "../../../utils/config/axiosInstance";
import { toast } from "react-toastify";

const WriteEntry = (props) => {
  const { data, setData, setStep } = props;
  const [message, setMessage] = useState("");

  let userId = sessionStorage.getItem("authUserId");
  const userToken = sessionStorage.getItem("accessToken");
  const handleSubmit = async () => {
    if (userId.startsWith("auth0|")) {
      userId = userId.slice(6);
    }
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL_DEVELOPMENT}/api/v1/mood/createmood`,
        {
          userId,
          mood: +data?.moodId + 1,
          message,
        }
      );
      // console.log(response.status);
      if (response.status == 200) {
        setStep(3);
        setData({ ...data, date: response?.data?.Date });
        toast.success(response.data?.message, {
          autoClose: 3000,
        });
      }
      // console.log("Mood journal response", response);
    } catch (error) {
      console.log("Mood journal error", error);
    }
    window.scrollTo(0, 0);
  };
  return (
    <div className="mood-selction-info">
      <p className="selected-option">
        {/* <img src={sad} /> */}
        <img src={data?.moodImage} />
        <span>
          I feel <span className="text-lowercase">{data?.moodName}</span> today.
        </span>
      </p>
      <Form>
        <Form.Group>
          <Form.Label>Write your journal entry</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Form.Group>
      </Form>
      <Button
        name="Submit Journal Entry"
        disabled={message == ""}
        type="submit"
        onClick={handleSubmit}
      />
    </div>
  );
};

export default WriteEntry;

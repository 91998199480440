import React, { useEffect } from "react";
import FreshdeskWidget from "@personare/react-freshdesk-widget";

const Legal = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerText = `window.fwSettings={
              'widget_id':101000447404
              };
              !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`;
    document.body.appendChild(script);
    const script2 = document.createElement("script");
    script2.src = "https://widget.freshworks.com/widgets/101000447404.js";
    script2.async = true;
    script2.defer = true;
    document.body.appendChild(script2);
  }, []);
  return (
    <div>
      <FreshdeskWidget url="https://medipartner.freshdesk.com/support/solutions/articles/101000447402-medipartner-privacy-policy-cover-today" />
    </div>
  );
};

export default Legal;

import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop({ children }) {
  const { pathname } = useLocation();
  // console.log("pathname", pathname);
  useEffect(() => {
    // console.log("calll", pathname);
    window.scrollTo(0, 0);
  }, [pathname]);
  //   const [scrollPosition, setScrollPosition] = useState(0);

  //   useEffect(() => {
  //     const updatePosition = () => {
  //       setScrollPosition(window.pageYOffset);
  //     };
  //     window.addEventListener("scroll", updatePosition);
  //     updatePosition();
  //     return () => window.removeEventListener("scroll", updatePosition);
  //   }, []);

  //   return scrollPosition;

  return children;
}
